import {SERVER_URL} from "../utils/globals";

export function Get(url) {
    console.log(SERVER_URL)
    const response = fetch(`${SERVER_URL}${url}`)
        .then((response) => {
            if(!response.ok) throw Error(response.statusText);
            return response.json();
        })
        .then((response) => {return response})
        .catch(err => {return null});
    return response;
}