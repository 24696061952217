import Dexie from "dexie";

const db = new Dexie('QuickMenuDB', { autoOpen: true });

db.version(1).stores({
    products: 'id,name,category,image,sale_price',
    categories: 'id,name',
});

const localDB = {
    cleanSession() {
        localStorage.clear();
        db.delete();
    },
    async updateList(key, data){
        await this.openDB();
        await db[key].bulkPut(data);
    },
    async addList(key, data){
        await this.openDB();
        await db[key].clear();
        await db[key].bulkAdd(data);
    },
    async deleteDB(key, id){
        await this.openDB();
        await db[key].where('id').equals(id).delete();
    },
    async getDB(key) {
        await this.openDB();
        return db[key].toArray();
    },
    async openDB(){
        if(!db.isOpen()){
            await db.open();
        }
    }
};

export default localDB;