import React from "react";
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {setupConfig} from '@ionic/react'

import '@ionic/react/css/core.css';

import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/variables.css';
import NotFound from "./components/NotFound";
import Home from './pages/Home';

setupConfig({mode: 'ios'})

const store_slug = window.location.pathname.replace('/','')

class App extends React.Component {
    render() {
        return(
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route path={`/${store_slug}`} exact>
                            {store_slug ? <Home store_slug={store_slug}/>:<NotFound />}
                        </Route>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        )
    }
}

export default App;
