import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

navigator.serviceWorker.register('/sw.js').then(function() { console.log("SW Service Worker Registered"); });
navigator.serviceWorker.register('/service-worker.js').then(function() { console.log("Service Worker Registered"); });