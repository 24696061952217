import React from 'react';
import LocalHeader from "./LocalHeader";


class NotFound extends React.Component {
    render() {
        return (
            <React.Fragment>
                <LocalHeader />
                <div style={{textAlign: 'center', position: 'fixed', top: '40%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <a href="https://bisttros.com"><img style={{width:'19em'}} src='/logo.svg' alt='Bisttros Quick Menú'></img></a>
                    <h1 className='message_error' style={{fontSize:'23pt', marginTop:'1em'}}>
                        Menú digital para tu restaurante con Código QR para que tus clientes puedan tenerlo en su dispositivo móvil.
                    </h1>
                </div>
            </React.Fragment>
        )
    }
}

export default NotFound;