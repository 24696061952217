import {Get} from "../utils/requests";
import localDB from "../utils/local_db";

const ProductService = {
    async list(store_slug) {
        let page = 1;
        let data = [];
        let has_next_page = true;
        while (has_next_page){
            let response = await Get(`/api/${store_slug}/products?page=${page}`);
            if(response){
                data.push(...response.results);
                has_next_page = response.next ? true:false;
                page++;
            }else {
                break;
            }
        }
        if(data){
            await localDB.addList('products', data)
        }else{
            await localDB.addList('products', [])
        }
    },
};

export default ProductService;