import Helmet from "react-helmet";
import React from "react";

class LocalHeader extends React.Component {
    render() {
        const manifest = {
            "name": 'Bisttros Quick Menú',
            "short_name": 'Bisttros Quick Menú',
            "description": 'Menú digital para tu restaurante con un QR Code para que tus clientes puedan tenerlo en su dispositivo móvil',
            "background_color":  '#ffffff',
            "theme_color": '#e44745',
            "display": "standalone",
            'scope': '/',
            'start_url': '/',
            "orientation": "portrait",
            "icons": [{
                "src": "https://bisttros.com/static/icons/icon-192x192.png",
                "sizes": "256x256",
                "type": "image/png",
                "purpose": "any maskable"
            },
                {
                    "src": "https://bisttros.com/static/icons/icon-512x512.png",
                    "sizes": "512x512",
                    "type": "image/png",
                    "purpose": "any maskable"
                }
            ]
        }

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], {type: 'application/javascript'});
        const manifestURL = URL.createObjectURL(blob);

        return(
            <Helmet>
                <link rel="icon" type="image/png" href="/icons/favicon.png"/>
                <meta name='theme-color' content='#e44745' />
                <meta name='description' content='Menú digital para tu restaurante con un QR Code para que tus clientes puedan tenerlo en su dispositivo móvil' />
                <link rel='apple-touch-icon' sizes='180x180' href='/icons/icon-512x512.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/icons/favicon-32x32.png' />
                <link rel='icon' type='image/png' sizes='16x16' href='/icons/favicon-16x16.png' />
                <link rel='shortcut icon' href='/icons/favicon.ico' />
                <link rel="canonical" href={`https://menu.bisttros.com`} />
                <meta property='og:title' content='Bisttros Quick Menú' />
                <meta property='og:description' content='Menú digital para tu restaurante con un QR Code para que tus clientes puedan tenerlo en su dispositivo móvil' />
                <meta property='og:site_name' content='Bisttros Quick Menú' />
                <meta property='og:url' content='https://bisttros.com' />
                <meta property='og:image' content='https://bisttros.com/media/flyer.jpg' />
                <link rel="manifest" id="manifest" href={manifestURL}/>
                <title>Bisttros Quick Menú</title>
            </Helmet>
        )
    }
}

export default LocalHeader