import React from 'react';

class StoreStyles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store_config: JSON.parse(localStorage.getItem('store'))
        }
    }

    componentDidMount() {
        this.setState({store_config: JSON.parse(localStorage.getItem('store'))})
    }

    render() {
        let url_title_font = encodeURI(`https://fonts.googleapis.com/css2?family=${this.state.store_config.title_font}&family=${this.state.store_config.text_font}&display=swap`)

        return <style global jsx={'true'}>{`
          @import url(${url_title_font});
          
          :root {
            --ion-text-color: ${this.state.store_config?.text_color || '#0A0908'};;
          }

          ion-content {
            --ion-background-color: ${this.state.store_config?.background_color || '#ffffff'};
          }

          ion-footer {
            background: ${this.state.store_config?.background_color || '#ffffff'};
          }

          ion-segment-button {
            color: ${this.state.store_config?.main_color || '#e44745'};
          }

          ion-toolbar {
            --background: ${this.state.store_config?.background_color || '#ffffff'};
          }

          ion-item {
            --border-color: ${this.state.store_config?.main_color + '60' || '#e44745'}
          }

          p {
            color: ${this.state.store_config?.text_color || '#0A0908'} !important;
          }

          h1, h2, h6, a {
            color: ${this.state.store_config?.main_color || '#e44745'};
          }
          
          h1, h2, h6 {
            font-family: ${this.state.store_config.title_font}, Arial;
          }
          
          .title_font{
            font-family: ${this.state.store_config.title_font}, Arial;
          }
          
          .product_name{
            font-size: 16pt;
          }
          .text_font{
            font-family: ${this.state.store_config.text_font}, Monaco !important;
          }

          .ion-color-primary {
            --ion-color-base: ${this.state.store_config?.main_color || '#e44745'} !important;
            --ion-color-base-rgb: ${this.state.store_config?.main_color || '#e44745'} !important;
            --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
            --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
            --ion-color-shade: ${this.state.store_config?.main_color || '#e44745'} !important;
            --ion-color-tint: ${this.state.store_config?.main_color || '#e44745'} !important;
          }
          
          .message_error{
              color: ${this.state.store_config?.text_color || '#0A0908'} !important; 
              text-align: center;
            }

        `}</style>
    }
}

export default StoreStyles;
