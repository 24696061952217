import Helmet from "react-helmet";
import React from "react";

class StoreHeader extends React.Component {
    render() {
        const manifest = {
            "name": this.props.data.store.name,
            "short_name": this.props.data.store.name,
            "description": this.props.data.store.store_profile.slogan,
            "background_color":  this.props.data.background_color,
            "theme_color": this.props.data.background_color,
            "display": "standalone",
            'scope': `/${this.props.data.store.slug}`,
            'start_url': `https://menu.bisttros.com/${this.props.data.store.slug}`,
            "orientation": "portrait",
            "icons": [{
                "src": `${this.props.data.icon}`,
                "sizes": "256x256",
                "type": "image/png",
                "purpose": "any maskable"
            },
                {
                    "src": `${this.props.data.icon}`,
                    "sizes": "512x512",
                    "type": "image/png",
                    "purpose": "any maskable"
                }
            ]
        }

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], {type: 'application/javascript'});
        const manifestURL = URL.createObjectURL(blob);

        return <Helmet>
            <title>{this.props.data.store.name} | Menú</title>
            <link rel="canonical" href={`https://menu.bisttros.com/${this.props.data.store.slug}`} />
            <link rel="icon" type="image/png" href={this.props.data.icon}/>
            <link rel="apple-touch-icon" href={this.props.data.icon}/>
            <link rel="manifest" id="manifest" href={manifestURL}/>
            <meta name="theme-color" content={this.props.data.background_color}/>
            <meta name='description' content={this.props.data.store.store_profile.slogan} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={this.props.data.store.name}  />
            <meta property='og:description' content={this.props.data.store.store_profile.slogan} />
            <meta property='og:site_name' content={this.props.data.store.name} />
            <meta property='og:url' content='https://menu.bisttros.com' />
            <meta property='og:image' content={this.props.data.icon} />
        </Helmet>
    }
}

export default StoreHeader