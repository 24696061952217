import {Get} from "../utils/requests";
import localDB from "../utils/local_db";

const CategoryService = {
    async list(store_slug) {
        let data = await Get(`/api/public-store-categories/${store_slug}`);
        if(data){
            data.unshift({id: '0', 'name':'Todos los productos'})
            await localDB.addList('categories', data)
        }else{
            await localDB.addList('categories', [])
        }
    },
};

export default CategoryService;