import React from 'react';
import {
    IonContent,
    IonFooter,
    IonHeader,
    IonSegment,
    IonCol,
    IonLabel,
    IonGrid,
    IonRow,
    IonThumbnail,
    IonNote,
    IonChip,
    IonSegmentButton,
    IonIcon,
    IonItem,
    IonPage,
    IonToolbar
} from '@ionic/react';
import CurrencyFormat from 'react-currency-format';
import {pricetagOutline} from 'ionicons/icons';
import StoreStyles from '../components/StoreStyles'
import StoreService from "../services/store_services";
import CategoryService from "../services/category_services";
import ProductService from "../services/product_services";
import localDB from "../utils/local_db";
import {SERVER_URL} from "../utils/globals";
import InfiniteList from 'react-infinite-scroll-list';
import StoreHeader from "../components/StoreHeader";
import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
import NotAccess from "../components/NotAccess";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            categories:[],
            products:[],
            all_products:[],
            products_category: [],
            store_slug: props.store_slug,
            selected_category:{
                id: '0',
                name: 'Todos los productos'
            }
        }
        this.categoryChange = this.categoryChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.nextData = this.nextData.bind(this);
    }

    async componentDidMount() {
        await this.loadData()
    }

    async loadData(){
        let slug = this.state.store_slug
        let data = await StoreService.get(slug)
        if(data){
            if(data.store.has_plan_access){
                await Promise.all([
                    CategoryService.list(data.store.id),
                    ProductService.list(data.branch_office)
                ])
            }
            localStorage.setItem('store', JSON.stringify(data));

            localDB.getDB('categories').then(categories =>{
                this.setState({categories})
            });

            localDB.getDB('products').then(products =>{
                this.setState({products: products.slice(0, 12), all_products: products, products_category:products})
            });

            this.setState({data, slug})
        }else{
            localStorage.setItem('store', null);
        }
        this.setState({loading: false})
    }

    async categoryChange(category){
        this.setState({selected_category: category})
        let products = category.id !== '0' ? this.state.all_products.filter((product) => product.category === category.id):this.state.all_products
        this.setState({products: products.slice(0, 12), products_category: products, loading:false})
    }

    nextData(){
        this.setState({products:this.state.products_category})
    }

    render() {
        if(!this.state.loading){
            if(this.state.data){
                if(this.state.data.store.has_plan_access){
                    return (
                        <React.Fragment>
                            <StoreStyles/>
                            <StoreHeader data={this.state.data} />
                            <IonPage>
                                <IonHeader style={{textAlign: 'center'}}>
                                    <IonToolbar>
                                        <img src={this.state.data.store.store_profile.logo} alt='logo' style={{paddingTop: '0.3em', height:'3.6em'}}/>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent fullscreen>
                                    <h2 style={{textAlign: 'center'}}>{this.state.selected_category.name.toUpperCase()}</h2>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeMd="12" sizeLg='6' offsetLg="3">
                                                <InfiniteList
                                                    root="container|viewport"
                                                    dataLength={this.state.products.length}
                                                    height={2}
                                                    hasMore={true}
                                                    onReachThreshold={() => this.nextData()}
                                                    threshold={3}
                                                >
                                                    {this.state.products.length > 0 ? this.state.products.map(product =>
                                                        <IonItem key={product.id}>
                                                            <IonThumbnail slot="start" style={{width:'3.9em', height:'3.9em'}}>
                                                                <img alt={product.name} src={`${SERVER_URL}${product.image}`} style={{borderRadius: '6px'}}/>
                                                            </IonThumbnail>
                                                            <IonLabel style={{whiteSpace: 'normal'}}>
                                                                <strong className="title_font product_name">{product.name}</strong>
                                                                <p className="text_font">{product.description}</p>
                                                            </IonLabel>
                                                            <IonNote slot="end">
                                                                <IonChip outline color='primary'>
                                                                    <IonIcon icon={pricetagOutline}/>
                                                                    <IonLabel>
                                                                        <CurrencyFormat className="text_font" value={product.price_branch_office} thousandSeparator='.' decimalSeparator=',' displayType={'text'}  prefix={'$'} />
                                                                    </IonLabel>
                                                                </IonChip>
                                                            </IonNote>
                                                        </IonItem>):<h3 style={{textAlign: 'center'}} className="text_font">NO HAY PRODUCTOS PARA ESTA CATEGORÍA</h3>}
                                                </InfiniteList>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonContent>

                                <IonFooter mode='md'>
                                    <IonSegment scrollable color="primary" value={this.state.selected_category.id} swipeGesture>
                                        {this.state.categories.map(category =>{
                                            return <IonSegmentButton value={category.id} key={category.id} className="text_font" onClick={() => this.categoryChange(category)}>
                                                {category.name}
                                            </IonSegmentButton>
                                        })}
                                    </IonSegment>
                                    <div style={{bottom: '0px', textAlign: 'center'}}>
                                        <a href='https://bisttros.com' target='_blank' without rel='noreferrer' style={{textDecoration: 'none', fontSize: '11px'}}>POWERED BY BISTTROS</a>
                                    </div>
                                </IonFooter>
                            </IonPage>
                        </React.Fragment>
                    );
                }else{
                    return <NotAccess/>
                }
            }else{
                return <NotFound/>
            }
        }else{
            return <Loading/>
        }
    }
};

export default Home;
