import React from 'react';


class NotAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store_config: JSON.parse(localStorage.getItem('store'))
        };
    }

    render() {
        return (
            <div style={{textAlign: 'center', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <h5 className='message_error'>{this.state.store_config?.store.name || 'Bisttros Quick Menú'}</h5>
                <br/>
                <img style={{width:'6em'}} src={this.state.store_config?.store.store_profile.logo} alt={this.state.store_config?.store.name || 'Bisttros Quick Menú'} ></img>
                <h6 className='message_error' style={{marginTop:'3em'}}>El menú se encuentra temporalmente desactivado.</h6>
            </div>
        )
    }
}

export default NotAccess;